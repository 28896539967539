.business
{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1em;
    margin-left: 1em;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    cursor: pointer;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

.photo {
    min-width: 120px;
    width: 130px;
    height: 130px;
    border-radius: 10px;
}

.rating {
    display: flex;
    flex-direction: row;
}


@media only screen and (max-width: 767px) {
    .photo {
        min-width: 90px;
        width: 90px;
        height: 90px;
    }
}