.root {
    position: absolute;
    left: 0; right: 0; top: 0;
    border: 2px solid #d3d3d3;
    border-radius: 1em;
    margin-bottom: 3em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 12em;
    padding: 0.25em;
    text-align: left;
    width: 50%;
  }

  @media only screen and (max-width: 925px) {
    .root {
      width: 90%;
      margin-top: 2em;
    }
  }

  @media only screen and (max-width: 640px) {
    .root {
      position: relative;
    }
  }

.logo {
  position: absolute;
  left: 0;right: 0; top: 0;
  height: 150px;
  margin-top: 1em;
  margin-left: 26%;
}

.banner {
  display: flex;
  width: 489px;
  height: 150px;
}

.form {
  padding: 1em;
  background-color: white;
  border-radius: 1em;
  width: fit-content;
  align-items:left;
  justify-content: left;  
}

.group {
  display: flexbox;
  margin: 1em;
  justify-content: left;
  align-content: flex-start;
  text-align: left;
}

.labelStyle {
  display: flex;
}

  label {
    font-weight: 600;
  }
  
  .button {
    margin-top: 1.4em;
  }

  .searchTerm {
    margin-top: 5px;
    height: 36px;
  }

.slider {
  position: relative;
  left: 0; right: 0; top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background_photo {
  border-radius: 10px;
  width: 100%;
}

.active {
  opacity: 1;
  transition-duration: 4s;
  transform: scale(1.08)
}

.notActive {
  opacity: 0;
  transition-duration: 4s ease;
}